export default {
  init() {
    /* 
     * Selecionando os elementos do menu mobile
    */
    let dropdown      = document.querySelector( '.header__menu-mobile' );
    const submenu     = document.querySelector( '.submenu' ); // Seleciona <ul> <li> <ul>
    const buttonClick = document.querySelector( '.check-button' ); // Seleciona o button
    const hamburguer  = document.querySelector( '.menu-icon' );

    /* 
     * Evento ao clicar no botão hamburguer do menu mobile
    */
    buttonClick.addEventListener( 'click', () => {
      dropdown.classList.toggle( 'show-dropdown' );

      if( submenu )
      { 
        submenu.classList.toggle( 'show-dropdown' ); 
      }

      hamburguer.classList.toggle( 'animate-button' );
    });

    /* 
     * Selecionando os elementos do Botão Search & Form de Pesquisa no Header
    */
    let showForm = document.querySelector( 
      '.header__menu-tablet-desktop form.search-form' 
    );

    const btnSearchClick = document.querySelector( 
      '.header__menu-tablet-desktop [data-search="button"]' 
    );

    /* 
     * Evento ao clicar no Botão Search no Header
    */
    btnSearchClick.addEventListener( 'click', () => {
      showForm.classList.toggle( 'show' );
    });

    /* 
     * Top button
     * @link https://www.cssigniter.com/add-back-top-button-wordpress-website/
    */
    jQuery(function ($) {
      let $window       = $(window);
      let $buttonTop    = $('.top-button');
      let scrollTimer;
    
      $buttonTop.on( 'click', function () {
        $( 'html, body' ).animate({
          scrollTop: 0,
        }, 400);
      });
    
      $window.on( 'scroll', function () {
        clearTimeout(scrollTimer);

        scrollTimer = setTimeout(function() {
         if ($window.scrollTop() > 700) {
            $buttonTop.addClass( 'show-top-btn' );
          } else {
            $buttonTop.removeClass( 'show-top-btn' );
          }         
        }, 250);
      });  
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
